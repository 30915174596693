<template>
  <div>
    <div class="download clear">
      <div class="clear main-container f-left">
        <div class="title">{{ $store.state.webConfig.download_title }}</div>
        <div class="sub-title">
          {{ $store.state.webConfig.download_description }}
        </div>
        <div class="clear main-nav">
          <div class="nav-container f-left">
            <div
              class="nav"
              :class="{ current: key == 'download_android_code' }"
              @click="key = 'download_android_code'"
            >
              <img
                v-if="key == 'download_android_code'"
                src="../images/download/android-select@3x.png"
              />
              <img v-else src="../images/download/android@3x.png" />
              <span>Android</span>
            </div>
            <div
              class="nav"
              :class="{ current: key == 'download_ios_code' }"
              @click="key = 'download_ios_code'"
            >
              <img
                v-if="key == 'download_ios_code'"
                src="../images/download/iOS-select@3x.png"
              />
              <img v-else src="../images/download/iOS@3x.png" />
              <span>iOS</span>
            </div>
            <!-- <div
              class="nav"
              :class="{'current':key=='download_xcx_code'}"
              @click="key='download_xcx_code'"
            >
              <img v-if="key=='download_xcx_code'" src="../images/download/app-select@3x.png" />
              <img v-else src="../images/download/app@3x.png" />
              <span>小程序</span>
            </div> -->
          </div>
          <img
            class="qrcode f-left"
            src="../images/download/client_qrcode.png"
            v-if="key == 'download_client_code'"
          />
          <img
            class="qrcode f-left"
            :src="$store.state.webConfig[key]"
            v-else
          />
        </div>
      </div>
      <img
        class="banner f-right"
        :src="$store.state.webConfig.download_banner"
      />
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
export default {
  name: "Download",
  components: {
    Footer,
  },
  data() {
    return {
      key: "download_android_code",
    };
  },
  methods: {
    downloadClient() {
      window.open("https://www.baijiayun.com/classroomdown/");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.download {
  background-color: white;
  min-height: 100%;
  padding: 0 220px;
  padding-top: 40px;
  padding-bottom: 132px;
  .banner {
    width: 540px;
    height: 640px;
  }
  .title {
    font-size: 92px;
    color: #595959;
    font-weight: 300;
    margin-bottom: 22px;
  }
  .sub-title {
    font-size: 52px;
    color: #595959;
    font-weight: 300;
  }
}
.main-container {
  width: auto;
  .main-nav {
    padding-top: 119px;
  }
  .nav {
    cursor: pointer;
    position: relative;
    margin-top: 27px;
    font-size: 26px;
    color: white;
    width: 287px;
    height: 70px;
    img {
      width: 100%;
      height: 100%;
    }
    span {
      position: absolute;
      top: 16px;
      right: 72px;
    }
    &:first-child {
      // margin-top: 50px;
      margin-top: 0;
    }
    &:nth-child(2) {
      span {
        right: 96px;
      }
    }
    &:nth-child(3) {
      span {
        right: 40px;
      }
    }
  }
  .qrcode {
    width: 212px;
    height: 212px;
    margin-left: 80px;
    margin-top: 28px;
  }
}
.footer {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
}

@mixin scale($ratio: 1) {
  .download {
    padding: 0 (220 * $ratio) + px;
    padding-top: 40px;
    padding-bottom: (132 * $ratio) + px;
    .title {
      font-size: (92 * $ratio) + px;
    }
    .sub-title {
      font-size: (52 * $ratio) + px;
    }
    .banner {
      width: (540 * $ratio) + px;
      height: (640 * $ratio) + px;
    }
  }
  .main-container {
    .main-nav {
      padding-top: (119 * $ratio) + px;
    }
    .nav {
      margin-top: (27 * $ratio) + px;
      font-size: (26 * $ratio) + px;
      width: (287 * $ratio) + px;
      height: (70 * $ratio) + px;
    }
    .qrcode {
      width: (212 * $ratio) + px;
      height: (212 * $ratio) + px;
      margin-left: (80 * $ratio) + px;
      margin-top: (28 * $ratio) + px;
    }
  }
}

@media screen and (max-width: 1600px) {
  @include scale(0.8333333333333333);
}

@media screen and (max-width: 1440px) {
  @include scale(0.75);
}

@media screen and (max-width: 1280px) {
  @include scale(0.6666666666666667);
}
</style>
